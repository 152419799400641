// JS Controller for global methods and functions

import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["formErrors"]

  connect() {
    this.element[this.identifier] = this
  }

  // Error form
  onPostError(event) {
    $.modal.close()
    this.scrollTop()
    this.formErrorsTarget.innerText = ""
    document.querySelector('[data-donations-target="btnConfirmForm"]').disabled = false
    const [data, status, xhr] = event.detail
    for (var i = 0; i < JSON.parse(xhr.response).length; i++) {
      var error = JSON.parse(xhr.response)[i]
      var li = document.createElement("li")
      li.appendChild(document.createTextNode(error))
      this.formErrorsTarget.appendChild(li)
    }
  }

  // Success form
  onPostSuccess(event) {
    this.formErrorsTarget.innerText = ""
    let [data, status, xhr] = event.detail
  }

  scrollTop() {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  submitForm(elem) {
    Rails.fire(elem, "submit")
  }
}
